import { Grid, Stack, Pagination } from "@mui/material";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import EditGameDialog from "../../components/EditGameDialog";
import DenseTable from "../../components/DenseTable";
import { ScheduleProps } from "../../components/DenseTable";
import { DeleteGame, GetGame, SaveGame } from "../../services/GameService";
import { ChangeEvent, useEffect, useState } from "react";
import { GetSchedule, GetScheduleCount } from "../../services/ScheduleService";

interface SchedulePerTournamentProps {
    tournamentId: string
}

const SchedulePerTournament = ({ tournamentId } : SchedulePerTournamentProps) => {
    const [editGameDialog, setEditGameDialog] = useState<boolean>(false);
    const [game, setGame] = useState<ScheduleProps>();
    const [confirmationDialog, setConfirmationDialog] = useState<boolean>(false);
    const [gameDelete, setGameDelete] = useState<string>("");
    const [pageCount, setPageCount] = useState<number>(1);
    const [count] = useState<number>(20);
    const [games, setGames] = useState<ScheduleProps[]>([]);

    useEffect(() => {
        LoadSchedule(tournamentId, 1, count);
    }, [ tournamentId ]);

    const getCurrentUTCDateTime = () => {
        return new Date().toISOString();
    };

    function OnGameAdd(gameType: number) {
        SaveGame({
            id: ``,
            scheduled: getCurrentUTCDateTime(),
            // Name: string,
            gameType: gameType,
            sideA: ``,
            sideB: ``,
            scoreA: 0,
            scoreB: 0,
            tournamentId: `tournaments/${0}-A`,
            youTube: [ ``, ``, `` ] 
        });
    }
    
    function OnGameEdit(id : string) {
        GetGame(id).then((game) => {
            // console.log(game);
            setGame(game);

            setEditGameDialog(true);
        });
    }

    function OnGameDelete(id : string) {
        setGameDelete(id);
        setConfirmationDialog(true);
    }

    function OnGameDeleteConfirmed() {
        DeleteGame(gameDelete).then((result) => {
            /* result => true - leaved successfully */
            setConfirmationDialog(false);
            window.location.reload();
        });
    }
    
    function handleChange(event: ChangeEvent<unknown>, page: number): void {
        LoadSchedule(tournamentId, page, count);
    }

    function OnEditGameDialogClose() {
        window.location.reload();
    }

    function LoadSchedule(tournamentId: string, page: number, count: number) {
        GetSchedule(tournamentId.toString(), page.toString(), count.toString())
        .then((data) => {
            GetScheduleCount(tournamentId).then(data => {
                setPageCount(Math.ceil(data / count));
            })
            setGames(data);
        });
    }

    return (
        <Grid container>
            <Grid item xs={12} mb={1}>
                <DenseTable  games={games} on_add={OnGameAdd} on_edit={OnGameEdit} on_delete={OnGameDelete}/>
                <EditGameDialog game={game} state={editGameDialog} setState={setEditGameDialog} on_close_dialog={OnEditGameDialogClose}></EditGameDialog>
                <ConfirmationDialog state={confirmationDialog} setState={setConfirmationDialog} callback={() => OnGameDeleteConfirmed()} />
            </Grid>
            <Grid item xs={12}>
                <Stack alignItems="center">
                    <Pagination count={pageCount} onChange={handleChange} />
                </Stack>
            </Grid>
        </Grid>
    );
}
export default SchedulePerTournament;