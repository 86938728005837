import axios from "axios";
import { Constants } from "../helpers/ConstantHelper";
import { PlayersProps } from "../pages/manage/players";

export const GetRating = () => {
    return axios.get(Constants.ApiUrls.rating + '2?top=999').then((response) => response.data);
};

export const GetProfile = (id : string) => {
    return axios.get(Constants.ApiUrls.player + 'profile/' + id).then((response) => response.data);
};

export const GetPlayers = () => {
    return axios.get(Constants.ApiUrls.player).then((response) => response.data);
};

export const UpdatePlayer = (player : PlayersProps) => {
    return axios.post(Constants.ApiUrls.player + `update`, player);
};