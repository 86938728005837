import PageTitle from "../../components/PageTitle";
import Layout from "../../layout/layout";
import TournamentGroup from "./tournamentgroup";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import TournamentPlayoff from "./tournamentplayoff";
import { GetTournament } from "../../services/TournamentsService";
import { useParams } from "react-router-dom";
import { stringify } from "querystring";
import TournamentCustom from "./tournamentCustom";
import { GetMappedUrl } from "../../helpers/UrlMapping";

enum TournamentType{
    Groups,
    Playoffs,
    Custom /* screenshot */
}

interface StageType {
    name: string,
    tournamentType: TournamentType,
    tournamentId: string,
    isDefault: boolean,
    config: string
}

function createGroup(
    name: string,
    tournamentType: TournamentType,
    tournamentId: string,
    isDefault: boolean,
    config: string
) {
    return { name, tournamentType, tournamentId, isDefault, config };
};

/* add placeorder and highlight advanced to playoff teams */
const json : string[] = [
    `[
        { "name": "Group A", "tournamentType": "Groups", "tournamentId": "49", "isDefault": false, "config": "{}" },
        { "name": "Group B", "tournamentType": "Groups", "tournamentId": "50", "isDefault": false, "config": "{}" },
        { "name": "Group C", "tournamentType": "Groups", "tournamentId": "51", "isDefault": false, "config": "{}" },
        { "name": "Group D", "tournamentType": "Groups", "tournamentId": "52", "isDefault": false, "config": "{}" },
        { 
            "name": "Playoff", 
            "tournamentType": "Playoffs", 
            "tournamentId": "53",
            "isDefault": true,
            "config": { "challongeId": "28tmayv5", "height": "520" }
        }
    ]`,
    `[
        { "name": "Group A", "tournamentType": "Groups", "tournamentId": "44", "isDefault": false, "config": "{}" },
        { "name": "Group B", "tournamentType": "Groups", "tournamentId": "45", "isDefault": false, "config": "{}" },
        { "name": "Group C", "tournamentType": "Groups", "tournamentId": "46", "isDefault": false, "config": "{}" },
        { "name": "Group D", "tournamentType": "Groups", "tournamentId": "47", "isDefault": false, "config": "{}" },
        { 
            "name": "Playoff", 
            "tournamentType": "Custom", 
            "tournamentId": "48",
            "isDefault": true,
            "config": { "screenshotUrl": "https://wcc-cossacks.s3.eu-central-1.amazonaws.com/images/wc23q/EXCEL_69Qe7s9FMm.png", "height": "600" }
        }
    ]`,
    `[
        { "name": "Group A", "tournamentType": "Groups", "tournamentId": "34", "isDefault": false, "config": "{}" },
        { "name": "Group B", "tournamentType": "Groups", "tournamentId": "35", "isDefault": false, "config": "{}" },
        { "name": "Group C", "tournamentType": "Groups", "tournamentId": "36", "isDefault": false, "config": "{}" },
        { "name": "Group D", "tournamentType": "Groups", "tournamentId": "37", "isDefault": false, "config": "{}" },
        { "name": "Group E", "tournamentType": "Groups", "tournamentId": "38", "isDefault": false, "config": "{}" },
        { "name": "Group F", "tournamentType": "Groups", "tournamentId": "39", "isDefault": false, "config": "{}" },
        { "name": "Group G", "tournamentType": "Groups", "tournamentId": "40", "isDefault": false, "config": "{}" },
        { "name": "Group H", "tournamentType": "Groups", "tournamentId": "41", "isDefault": false, "config": "{}" },
        { 
            "name": "Playoff", 
            "tournamentType": "Playoffs", 
            "tournamentId": "42",
            "isDefault": true,
            "config": { "challongeId": "23w7osl4", "height": "730" }
        }
    ]`
];

const Tournament = () => {
    const params = useParams();
    const [loading, setLoading] = useState(true);
    const [title, setTitle] = useState<string>(``);

    const [group, setGroup] = useState<string>(``);
    const [groups, setGroups] = useState<StageType[]>();

    useEffect(() => {
        debugger;
        let id = GetMappedUrl(encodeURIComponent(params.id ?? "0"));
        GetTournament(id).then((t) => {
            const jsonData : StageType[] = JSON.parse(t.config);

            const groupsTmp : StageType[] = jsonData.map((item: StageType) =>
                createGroup(
                    item.name,
                    TournamentType[item.tournamentType.toString() as keyof typeof TournamentType], // Convert to enum
                    item.tournamentId,
                    item.isDefault,
                    typeof item.config === "object" ? JSON.stringify(item.config) : item.config
                )
            );

            var defaultRow = groupsTmp.find(item => item.isDefault);
            setGroup(defaultRow?.name ?? groupsTmp[0].name);

            setGroups(groupsTmp);

            setTitle(t.name);

            setLoading(false);
        });
    }, []);

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setGroup(newValue);
    };

    function renderTabPanelItem(tournamentType : TournamentType, name : string, tournamentId : string, config: string) {
        switch(tournamentType) {
            case TournamentType.Groups:
                return (
                    <TabPanel value={name}>
                        <TournamentGroup name={name} tournamentId={tournamentId}></TournamentGroup>
                    </TabPanel>
                );
            case TournamentType.Playoffs:
                var configObject = JSON.parse(config);
                return (
                    <TabPanel value={name}>
                        <TournamentPlayoff
                            name={name}
                            tournamentId={tournamentId}
                            challongeId={configObject.challongeId}
                            height={configObject.height}/>
                    </TabPanel>
                );
            case TournamentType.Custom:
                var configObject = JSON.parse(config);
                return (
                    <TabPanel value={name}>
                        <TournamentCustom
                            name={name}
                            tournamentId={tournamentId}
                            screenshotUrl={configObject.screenshotUrl}
                            height={configObject.height}/>
                    </TabPanel>
                );
            default:
                return <></>;
        }
    }

    return (
        <Layout>
            <Grid container>
                <Grid item xs={12} textAlign={"center"} mb={5}>
                    <PageTitle text={title} />
                </Grid>
                <Grid item xs={12}>
                    {loading || groups === undefined ? (
                        <p>Loading...</p>
                    ) : (
                        <TabContext value={group}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={handleChange} aria-label="lab API tabs example">
                                {groups.map((row: { name: any; }) => (
                                    <Tab label={`${row.name}`} value={row.name} />
                                ))}
                                </TabList>
                            </Box>
                            {groups.map((row: { tournamentType: TournamentType; name: string; tournamentId: string; config: string; }) => (
                                renderTabPanelItem(row.tournamentType, row.name, row.tournamentId, row.config)
                            ))}
                        </TabContext>
                    )}
                </Grid>
            </Grid>
        </Layout>
    );
};
export default Tournament;

