import { Grid } from "@mui/material";
import SchedulePerTournament from "../schedule/schedulepertournament";

interface TournamentCustomProps {
    name: string,
    tournamentId: string,
    screenshotUrl: string,
    height: string
}

const TournamentCustom = ({ name, tournamentId, screenshotUrl, height } : TournamentCustomProps) => {

    function getBracket() {
        const rawHTML = `<iframe src="${screenshotUrl}" width="100%" height="${height}" frameborder="0" scrolling="auto" allowtransparency="true"></iframe>`;
    
        return (
            <div dangerouslySetInnerHTML={{ __html: rawHTML }} />
        );
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                {getBracket()}
            </Grid>
            <Grid item xs={12}>
                <SchedulePerTournament tournamentId={`tournaments/${tournamentId}-A`}></SchedulePerTournament>
            </Grid>
        </Grid>
    );
};

export default TournamentCustom;
